import React, { useEffect } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import TextField from '../../components/forms/input/textfield'
import { Container, Col, Row } from "react-bootstrap"
import { assets } from '../../utils/index';
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import './login.scss'
import { toast } from "react-toastify";
import Password from '../../components/forms/password/password';
import Button from '../../components/forms/button/button';
import { useAuthContext } from '../../contexts/AuthContext';

export default function Login() {
  const { login, getLoggedInState } = useAuthContext()
  const nav = useNavigate()
  const isLoggedIn = getLoggedInState()
  const initialValues = {
    email: '',
    password: ''
  }
  const validate = Yup.object({
    email: Yup.string().required("Email Required!"),
    password: Yup.string().required("Password Required!")
  });

  useEffect(() => {
    if (isLoggedIn) {
      nav('/dashboard')
    }
  }, [isLoggedIn])

  const handleLoginFormSubmit = (values) => {
    const payload = {
      username: values.email,
      password: values.password,
    }
    login(payload).then(resp => {
      if (resp.success) {
        nav('/dashboard')
      }
    }).catch(err => {
      toast.error(err?.response?.data?.message || 'Something Went Wrong'
        , { autoClose: 5000, className: 'toast-fail' })
    })
  }

  return (
    <div className='login-wrapper'>
      <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
        <img className='fletcher-logo' src={assets.fletcherLogo} />
        <img className='fletcher-text' src={assets.fletcherNewLogo} />
      </Row>

      <Row>
        <div className='login-heading'>Login</div>
      </Row>

      <Row className='form'>
        <Formik
          initialValues={initialValues}
          validationSchema={validate}
          onSubmit={handleLoginFormSubmit}
        >
          {(formik) => (
            <Form>
              <div className='login-field'>
                <TextField
                  type='text'
                  name='email'
                  placeholder='Enter Email'
                  customClass='primary-input'
                />
              </div>
              <div className='login-field'>
                <Password
                  name='password'
                  placeholder='Enter Password'
                  customClass='primary-input'
                />
              </div>
              <div className='login-field login-button'>
                <Button variant='btn-dark'>Login</Button>
              </div>
            </Form>
          )}
        </Formik>
      </Row>
      <Row>
        <div className='forgot-password'>
          Forgot your Login Details ? <span className='help' onClick={() => nav('/reset-password')}>Get Help Signing In</span>
        </div>
      </Row>
    </div>
  )
}
