import React from "react";

// if(document.body.classList.contains('website')) {
//   import "bootstrap/dist/css/bootstrap.min.css";
// }
import "../../website/assets/styles/global.scss";
import "../../website/components/layout/layout.scss";

const ReactBootstrapStyling = React.lazy(() => import("./reactBootstrapStyling"));

const WebsiteScreen = ({ children }) => {
  return <>
    <React.Suspense fallback={<></>}>
      <ReactBootstrapStyling />
    </React.Suspense>
    {children}
  </>;
};
export default WebsiteScreen