import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { Row } from 'react-bootstrap'
import { assets } from '../../utils'
import Password from '../../components/forms/password/password';
import Button from '../../components/forms/button/button';
import { Form, Formik } from 'formik';
import { useLocation, useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../contexts/AuthContext';
import { toast } from "react-toastify";
import '../login/login.scss'
import TextField from '../../components/forms/input/textfield';

export default function PasswordResetEmail() {
    const { changePasswordEmail } = useAuthContext();
    const nav = useNavigate()
    const initialValues = { email: '' }

    const validate = Yup.object({
        email: Yup.string().required("Email Required!"),
    });

    useEffect(() => {

    }, [])

    const handlePasswordChangeSubmit = (vals) => {
        const payload = {
            email: vals.email
        }
        changePasswordEmail(payload).then(resp => {
            if(resp?.data?.success){
                toast.success(resp.data.message,{autoClose:5000, className:'toast-success'})
                nav('/login')
            } else {
                toast.error('Something went wrong',{autoClose:5000, className:'toast-fail'})
            }
        })
    }

    return (
        <div className='login-wrapper'>
            <div>
                <Row>
                    <img className='fletcher-logo' src={assets.fletcherLogo} />
                    <img className='fletcher-text' src={assets.fletcherNewLogo} />
                </Row>
                <Row>
                    <div className='login-heading signup-heading'>Forgot Password</div>
                    <div className='signup-sub-heading'>Enter your Email to reset Password</div>
                </Row>
            </div>
            <div>
                <Row className='form'>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validate}
                        onSubmit={handlePasswordChangeSubmit}
                    >
                        {(formik) => (
                            <Form>
                                <div className='login-field'>
                                    <TextField
                                        type='text'
                                        name='email'
                                        placeholder='Enter Email'
                                        customClass='primary-input'
                                    />
                                </div>
                                <div className='login-field login-button'>
                                    <Button variant='btn-dark'>Submit</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Row>
                <Row>
                    <div className='forgot-password'>
                        Back to <span className='help' onClick={() => nav('/login')}>Login</span>
                    </div>
                </Row>
            </div>
        </div>
    )
}

