import { createContext, useContext } from "react";
import { API } from '../api';

const DashboardContext = createContext();

const DashboardProvider = ({children}) => {
    
    const getUserList = (config) => {
        return new Promise((resolve,reject)=>{
            API.getUsers(config).then(response=>{
                resolve(response.data)
            }).catch((error)=>{
                reject(error)
            })
        })
    }
    const addUsers = (config) => {
        return new Promise((resolve,reject)=>{
            API.addUsers(config).then(response=>{
                resolve(response.data)
            }).catch((error)=>{
                reject(error)
            })
        })
    }

    const editUser = (id,payload) => {
        return new Promise((resolve,reject)=>{
            API.editUser(id,payload).then(response=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    }

    const deleteUser = (id) => {
        return new Promise((resolve,reject)=>{
            API.deleteUser(id).then(response=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    }

    return (
        <DashboardContext.Provider value={{
            getUserList,
            editUser,
            addUsers,
            deleteUser
        }}>
            {children}
        </DashboardContext.Provider>
    )
}

export const useDashboardContext = () => useContext(DashboardContext);

export default DashboardProvider;