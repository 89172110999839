import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { pageHeading } from '../../utils';
import './header.scss';


export default function Header() {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState('')
    useEffect(() => {
        const currentPage = location.pathname.split('/').slice(-1)[0]
        setCurrentPage(pageHeading[currentPage])

    }, [location])
    return (
        <>
            <div className='page-heading'>{currentPage}</div>
            <hr className='hr'/>
        </>
    )
}
