import fletcherText from '../assets/icons/fletcher.svg'
import fletcherLogo from '../assets/images/fletcher_icon.png'
import visibilityOff from '../assets/icons/visibility_off.svg'
import visibilityOn from '../assets/icons/visibility_on.svg'
import usersIcon from '../assets/icons/users.svg'
import editIcon from '../assets/icons/edit.svg'
import trashIcon from '../assets/icons/trash.svg'
import searchIcon from '../assets/icons/search.svg'
import chevronDown from '../assets/icons/chevron_down.svg'
import fletcherLogoBlack from '../assets/icons/fletcher-logo-black.svg'
import fletcherTextWhite from '../assets/icons/fletcher-text-white.svg'
import fletcherNewLogo from '../assets/icons/Fletcher_new_logo.svg'
import redCross from '../assets/icons/red-cross.svg'
import defaultSort from '../assets/icons/default_sort.svg'
import crossIcon from '../assets/icons/cross-icon.svg'
import logoutIcon from '../assets/icons/logout.svg'
import userPlaceholder from '../assets/icons/user-placeholder.svg'
import passwordInfo from '../assets/icons/password-info-icon.svg'
import fletcherNewWhite from '../assets/icons/fletcher-new-white.svg'

export const assets = {
    fletcherText,
    fletcherLogo,
    visibilityOff,
    visibilityOn,
    usersIcon,
    editIcon,
    trashIcon,
    searchIcon,
    chevronDown,
    fletcherLogoBlack,
    fletcherTextWhite,
    redCross,
    defaultSort,
    crossIcon,
    logoutIcon,
    userPlaceholder,
    fletcherNewLogo,
    passwordInfo,
    fletcherNewWhite
}

export const pageHeading = {
    users: 'Users'
}