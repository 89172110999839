import React from 'react'
import Header from '../header/header'
import { Outlet } from 'react-router-dom';
import Sidebar from '../sidebar/sidebar';
import './layout.scss';

export default function Layout() {
    return (
        <>
            <div className='main-wrapper'>
                <div className='sidebar-menu'>
                    <Sidebar />
                </div>
                <div className='content'>
                    <div className='layout-header-wrapper'>
                        <Header />
                    </div>
                    <div className='dashboard-components-holder'>
                        <Outlet>

                        </Outlet>
                    </div>
                </div>
            </div>
        </>
    )
}
