import axios from "axios"
import { GLOBALAPPCONFIG } from "../global-config"

const axiosConfig = {
    baseURL: GLOBALAPPCONFIG.axiosBaseUrl
}

// export default axios.create(axiosConfig)
export default axios.create()

