import React, { useEffect, useRef, useState } from 'react'
import './users.scss'
import { assets } from '../../../utils'

//500 | 50 -> 10 pages

function Footer({ totalCount, reFetchUserData, prevAndNext, searchedInputValue }) {
    const [showDDN, setShowDDN] = useState(false);
    const paginationOptions = [10, 20, 50, 100]
    const [limitValue, setLimitValue] = useState(paginationOptions[0])
    const [pageview, setPageView] = useState([])
    const [currentPage, setCurrentPage] = useState({ val: 1 })
    const notReFetchRef = useRef(false)

    useEffect(() => {
        if (totalCount > limitValue) {
            const arr = [{ name: 'Prev', val: 'prev' }]
            const pages = Math.ceil(totalCount / limitValue)
            // if(pages>5){}
            for (let i = 0; i < pages - 1; i++) {
                if (i === 3 && pages > 5) {
                    break
                }
                arr.push({ name: i + 1, val: i + 1, active: false })
            }
            if (pages > 5) {
                arr.push({ name: '...', val: 'more' })
            }
            arr.push({ name: pages, val: pages })
            // console.log(arr)
            arr.push({ name: 'Next', val: 'next' })
            setPageView(arr)
        } else {
            setPageView([])
        }
        // reFetchUserData({limit:limitValue,offset:0})
    }, [limitValue, totalCount])

    useEffect(()=> {
        if(searchedInputValue.length){
            notReFetchRef.current = true
            setCurrentPage({ val: 1 })
        }
    },[searchedInputValue])

    const handleLimitChange = (value) => {
        setLimitValue(value);
        setShowDDN(false);
        const obj = { ...currentPage }
        obj.val = 1
        notReFetchRef.current = false
        setCurrentPage(obj)
    }

    const handlePageChange = (page) => {
        if (page.val === 'more') {
            return
        }
        if (page.val === 'next') {
            if (!prevAndNext.next) return
            setCurrentPage({ val: currentPage.val + 1 })
        } else if (page.val === 'prev') {
            if (!prevAndNext.prev) return
            setCurrentPage({ val: currentPage.val - 1 })
        } else {
            setCurrentPage({ val: page.val })
        }
    }

    useEffect(() => {
        handlePaginationObject()
        if(notReFetchRef.current){
            return
        }
    }, [currentPage])

    const handlePaginationObject = () => {
        const limit = limitValue;
        const offset = limitValue * (currentPage.val - 1)
        // console.log({ limit, offset })
        reFetchUserData({ limit, offset })
    }

    return (
        <div className='footer-wrapper'>
            <div className='limit-setter'>
                <span>Number of rows</span>
                <span>
                    <div className='limit-ddn'>
                        <div onClick={() => setShowDDN(!showDDN)} className='ddn-btn'>
                            <span>{limitValue}</span>
                            <img className='down-icon' src={assets.chevronDown} />
                        </div>
                        {showDDN && <div className='limit-options'>
                            {paginationOptions.map(option => <span onClick={() => handleLimitChange(option)} key={option}>{option}</span>)}
                        </div>}
                    </div>
                </span>
            </div>
            <div className='pages-view'>
                {pageview.map(page =>
                    <div key={page.val} className='page'>
                        <div onClick={() => handlePageChange(page)}
                            className={`page-btn ${currentPage.val == page.name ? 'page-btn-active' : ''} ${page.val === 'more' && 'no-action'}
                            ${(page.val === 'next' && !prevAndNext.next) && 'nextPrev-disabled'} ${(page.val === 'prev' && !prevAndNext.prev) && 'nextPrev-disabled'}`}>
                            {/* more button number labels */}
                            {(page.val === 'more' && !pageview.map(el => el.val).includes(currentPage.val)) &&
                                <span className='more-text'>{currentPage.val}</span>}
                            <span>{page.name}</span>
                        </div>
                    </div>)}
            </div>
        </div>
    )
}

export default React.memo(Footer)
