import { createContext, useContext, useState } from "react";
import { API } from "../api";

const AuthContext = createContext();

const saveDataInLocalStorage = (data) => {
    localStorage.setItem("token", data.token)
    localStorage.setItem("user", JSON.stringify(data.user))
}

const AuthProvider = ({ children }) => {
    const [userData, setUserData] = useState({})
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const login = (payload) => {
        return new Promise((resolve, reject) => {
            API.login(payload).then(resp => {
            if (resp?.data?.success) {
                    saveDataInLocalStorage(resp.data)
                    setUserData(resp.data.user)
                    setIsLoggedIn(true)
                    resolve(resp.data)
                }
            }).catch(err =>reject(err))
        })
    }

    const getLoggedInState = () => {
        if (localStorage.getItem('token')?.length && localStorage.getItem('user')?.length) {
            return true
        }
        return false
    }
    const logout = () => {
        localStorage.clear()
        return new Promise((resolve, reject) => {
            resolve('')
            API.logout().then(resp => {
            }).catch(err => reject(err))
        })
    }
    const changePassword = (payload) => {
        return new Promise((resolve, reject) => {
            API.changePassword(payload).then(resp => {
                resolve(resp)
            }).catch(err => reject(err))
        })
    }
    
    const changePasswordEmail = (payload) => {
        return new Promise((resolve, reject) => {
            API.changePasswordEmail(payload).then(resp => {
                resolve(resp)
            }).catch(err => reject(err))
        })
    }

    return (
        <AuthContext.Provider value={{
            userData,
            isLoggedIn,
            setIsLoggedIn,
            login,
            logout,
            getLoggedInState,
            changePassword,
            changePasswordEmail
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthProvider