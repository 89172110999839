import React from 'react';
import './button.scss'

export default function Button({
  variant,
  styles,
  clickHandler=()=>{},
  children,
  ...props}) {

  return (
    <button {...props} className={`button ${variant} ${props.disabled && 'disabled-button'}`} style={styles}
     onClick={()=>clickHandler()}>{children}</button>
  )
}
