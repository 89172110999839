import logo from './logo.svg';
import { BrowserRouter as Router } from "react-router-dom"
import AuthProvider from './contexts/AuthContext';
import AppRoutes from './routes';
import { ToastContainer } from 'react-toastify';
import DashboardProvider from './contexts/DashboardContext';
import { AppContextProvider as WebsiteAppContextProvider } from "./website/contexts/app-context"

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <WebsiteAppContextProvider>
      <AuthProvider>
        <DashboardProvider>
          <Router>
            <ToastContainer position="top-right" theme="colored" />
            <AppRoutes />
          </Router>
        </DashboardProvider>
      </AuthProvider>
    </WebsiteAppContextProvider>
  );
}

export default App;
