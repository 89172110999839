import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import Table from '../../../components/table/table'
import { useDashboardContext } from '../../../contexts/DashboardContext'
import { userListMock } from '../../../utils/mock';
import { DropdownButton } from "react-bootstrap"
import './users.scss'
import UserActionColumns from './userActionColumns';
import Footer from './footer';
import { assets } from '../../../utils';
import { toast } from "react-toastify";
import Deletepopup from '../../../components/deletePopup/deletepopup';
import AddEditUser from '../../../components/add-user/addEditUser';

export default function Users() {
  const [userList, setUserList] = useState([]);
  const [showDDN, setShowDDN] = useState(false);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [prevAndNext, setPrevAndNext] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [actionType, setActionType] = useState('');
  const [searchedInputValue, setSearchedInputValue] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedUserType, setSelectedUserType] = useState('User Type');
  const { getUserList, editUser, addUsers, deleteUser } = useDashboardContext();
  const [userListParams, setUserListParams] = useState({
    params: {
      limit: '',
      offset: '',
      search: ''
    }
  })
  let debounceTimer;
  const cols = [
    {
      accessor: "name",
      Header: "Name",
      Cell: ({ row }) => <span className='user-name-image'>
        <img src={row.original.picture ? row.original.picture : assets.userPlaceholder} alt='user-image' />
        <p className='text-capitalize'>{row.original.name || '- -'}</p></span>
    },
    {
      accessor: "email",
      Header: "Email",
      width: 200
    },
    {
      accessor: "gender",
      Header: "Gender",
      Cell: ({ row }) => <p className='text-capitalize'>{row.original.gender || '- -'}</p>
    },
    {
      accessor: "usertype",
      Header: "User Type"
    },
    {
      accessor: "",
      Header: "Action",
      Cell: ({ row }) => <UserActionColumns id={row.original.id}
        deletehandler={() => { setSelectedUserId(row.original.id); setShowDelete(true) }}
        edithandler={() => {
          setSelectedUserId(row.original.id);
          setShowAddPopup(true);
          setActionType('edit');
          setSelectedRow(row.original)
        }}
        is_flagged={row.original.is_flagged}
        flagHandler={flagHandler} />
    }
  ]
  const getUsers = () => {
    setUserLoading(true)
    getUserList(userListParams).then(resp => {
      setTotalUserCount(resp.count)
      setPrevAndNext({ next: resp.next, prev: resp.previous })
      setUserList(resp.results.data)
    }).catch(err => { })
      .finally(() => setUserLoading(false))
  }
  useEffect(() => {
    getUsers()
  }, [userListParams])

  const handleUserTypeFilter = (val) => {
    setShowDDN(false)
    const obj = { ...userListParams }
    obj.params.usertype = val
    if (val === 'userType') {
      delete obj.params.usertype
    }
    const selection = val==='userType'?'User Type':val
    setSelectedUserType(selection)
    setUserListParams(obj)
  }

  const flagHandler = (id, is_flagged) => {
    setUserLoading(true)
    editUser(id, { is_flagged }).then(resp => {
      if (resp?.data?.success) {
        // getUsers();
        const existingList = [...userList]
        existingList.find(el => el.id === id).is_flagged = is_flagged
        setUserList(existingList)
        setUserLoading(false)
      }
    }).catch(err => setUserLoading(false))
      .finally(() => setUserLoading(false))
  }
  const handleDelete = () => {
    setShowDelete(false)
    setUserLoading(true)
    deleteUser(selectedUserId).then(resp => {
      getUsers();
      setSelectedUserId('')
    }).catch(err => { setUserLoading(false) })
      .finally(() => setUserLoading(false))
  }

  const reFetchUserData = (config) => {
    const obj = { ...userListParams }
    obj.params.limit = config.limit
    obj.params.offset = config.offset
    setUserListParams(obj)
  }

  const handleDebounce = ({ target: { value } }) => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      handleInputChange(value)
    }, 1000)
  }

  const handleInputChange = (value) => {
    const obj = { ...userListParams }
    obj.params.search = value
    delete obj.params.offset
    setSearchedInputValue(value)
    setUserListParams(obj)
  }

  const formSubmitHandler = (type, vals) => {
    const payload = {
      name: vals.fullName,
      email: vals.email,
      usertype: 'member'
    }
    if (type === 'add') {
      addUsers(payload).then(resp => {
        toast.success('User Added Successfully', { autoClose: 5000, className: 'toast-success' })
        setShowAddPopup(false)
      }).catch(err => {
        toast.error('Somthing went wrong', { autoClose: 5000, className: 'toast-fail' })
        setShowAddPopup(false)
      })
    } else if (type === 'edit') {
      editUser(selectedRow.id, payload).then(resp => {
        toast.success('User Edited Successfully', { autoClose: 5000, className: 'toast-success' })
        setShowAddPopup(false)
        getUsers()
      }).catch(err => {
        toast.error('Somthing went wrong', { autoClose: 5000, className: 'toast-fail' })
        setShowAddPopup(false)
      })
    }
  }

  const columns = useMemo(() => cols, [userList])
  const rowData = useMemo(() => userList, [userList])
  return (
    <div className='users-wrapper'>
      <Row className="filter-actions">
        <div className='left-search'>
          <input type='text' onChange={handleDebounce} placeholder='Search user' />
          <img className='assets-icon' src={assets.searchIcon} />
        </div>
        <div className='right-actions'>
          {/* <div className='user-type-ddn'>
            <div onClick={() => setShowDDN(!showDDN)} className='ddn-btn'>
              <span className='selected-user-type'>{selectedUserType}</span>
              <img className='down-arrow' src={assets.chevronDown} />
            </div>
            {showDDN && <div className='user-type-options'>
              <div style={{ opacity: '0.5' }} onClick={() => handleUserTypeFilter('userType')}>User Type</div>
              <div onClick={() => handleUserTypeFilter('member')}>Member</div>
              <div onClick={() => handleUserTypeFilter('physician')}>Physician</div>
            </div>}
          </div> */}
          <div className='add-user'>
            <button onClick={() => { setShowAddPopup(true); setActionType('add'); setSelectedRow({}) }}>Add New +</button>
          </div>
        </div>
      </Row>
      <Row className="main-content">
        <Table
          columns={columns}
          data={rowData}
          isLoading={userLoading}
          height={'68vh'}
        />
        <Footer totalCount={totalUserCount} prevAndNext={prevAndNext}
          reFetchUserData={useCallback(reFetchUserData, [])}
          searchedInputValue={searchedInputValue} />
      </Row>
      <Row></Row>
      <AddEditUser show={showAddPopup}
        formSubmitHandler={formSubmitHandler}
        setShow={setShowAddPopup} actionType={actionType}
        selectedRow={selectedRow} />
      <Deletepopup show={showDelete} label={'Delete users'} handleDelete={handleDelete}
        content='Are you sure you want to delete this user?' setShow={setShowDelete}
        primaryBtnText='Delete User' />
    </div>
  )
}
