import React from 'react'
import { assets } from '../../../utils'

export default function UserActionColumns({id, deletehandler, edithandler, flagHandler, is_flagged}) {
  return (
    <div className='user-action-wrapper'>
        <img className='edit-icon' src={assets.editIcon} onClick={edithandler}/>
        <img className='trash-icon' onClick={deletehandler} src={assets.trashIcon}/>
        <button onClick={()=>flagHandler(id,!is_flagged)} className={`${is_flagged?'flag-user':'unflag-user'}`}>{is_flagged?'Unflag User':'Flag User'}</button>
    </div>
  )
}
