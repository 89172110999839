import React from 'react'
import Modal from 'react-modal';
import './deletepopup.scss'
import { assets } from '../../utils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');

export default function Deletepopup(props) {
    const { show, label, setShow, content, handleDelete, primaryBtnText } = props
    return (
        <Modal
            isOpen={show}
            // onAfterOpen={afterOpenModal}
            onRequestClose={setShow}
            style={customStyles}
            contentLabel="delete-popup"
        >
            <div className='delete-popup-wrapper'>
                <div className='header'>
                    <span className='label'>{label}</span>
                    <img onClick={() => setShow(false)} src={assets.crossIcon}/>
                </div>
                <div className='content'>
                {content}
                </div>
                <div className='actions'>
                    <button className='cancel-btn' onClick={() => setShow(false)}>Cancel</button>
                    <button className='delete-btn' onClick={handleDelete}>{primaryBtnText}</button>
                </div>
            </div>
        </Modal>
    )
}
