export const GraphicLogo = () => {
  return (
    <svg
      width="168"
      height="25"
      viewBox="0 0 168 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1821 0.280209V5.07042H6.05503V10.2452H13.6327V14.8956H6.05503V24.8257H0V0.280209H16.1821Z"
        fill="white"
      />
      <path
        d="M25.4196 20.2103H33.3513V24.8257H19.3646V0.280209H25.4196V20.2103Z"
        fill="white"
      />
      <path
        d="M42.2944 5.07042V10.0355H50.4032V14.6508H42.2944V20.0355H51.4655V24.8257H36.2394V0.280209H51.4655V5.07042H42.2944Z"
        fill="white"
      />
      <path
        d="M73.2783 0.280209V5.07042H66.6921V24.8257H60.6371V5.07042H54.051V0.280209H73.2783Z"
        fill="white"
      />
      <path
        d="M75.2903 12.518C75.2903 10.0937 75.8214 7.93755 76.8837 6.04944C77.946 4.13802 79.4214 2.65783 81.3099 1.60888C83.222 0.536619 85.382 0.000488281 87.7898 0.000488281C90.7406 0.000488281 93.2665 0.769719 95.3675 2.30818C97.4684 3.84664 98.873 5.94454 99.5812 8.60189H92.9242C92.4285 7.57625 91.7203 6.79536 90.7997 6.25923C89.9026 5.7231 88.8757 5.45503 87.719 5.45503C85.8541 5.45503 84.3433 6.09606 83.1866 7.37811C82.0299 8.66016 81.4515 10.3734 81.4515 12.518C81.4515 14.6625 82.0299 16.3758 83.1866 17.6578C84.3433 18.9399 85.8541 19.5809 87.719 19.5809C88.8757 19.5809 89.9026 19.3128 90.7997 18.7767C91.7203 18.2406 92.4285 17.4597 92.9242 16.4341H99.5812C98.873 19.0914 97.4684 21.1893 95.3675 22.7278C93.2665 24.2429 90.7406 25.0005 87.7898 25.0005C85.382 25.0005 83.222 24.476 81.3099 23.4271C79.4214 22.3548 77.946 20.8746 76.8837 18.9865C75.8214 17.0984 75.2903 14.9422 75.2903 12.518Z"
        fill="white"
      />
      <path
        d="M124.818 0.280209V24.8257H118.763V14.7208H109.344V24.8257H103.289V0.280209H109.344V9.89559H118.763V0.280209H124.818Z"
        fill="white"
      />
      <path
        d="M135.244 5.07042V10.0355H143.353V14.6508H135.244V20.0355H144.415V24.8257H129.189V0.280209H144.415V5.07042H135.244Z"
        fill="white"
      />
      <path
        d="M161.023 24.8257L155.853 15.5599H154.402V24.8257H148.346V0.280209H158.509C160.468 0.280209 162.133 0.618204 163.502 1.29419C164.895 1.97018 165.933 2.90259 166.618 4.0914C167.302 5.2569 167.645 6.56226 167.645 8.00748C167.645 9.63918 167.173 11.0961 166.228 12.3781C165.308 13.6602 163.938 14.5693 162.121 15.1054L167.857 24.8257H161.023ZM154.402 11.3292H158.155C159.264 11.3292 160.091 11.0611 160.634 10.525C161.2 9.98883 161.483 9.23126 161.483 8.25224C161.483 7.31984 161.2 6.58557 160.634 6.04944C160.091 5.51331 159.264 5.24524 158.155 5.24524H154.402V11.3292Z"
        fill="white"
      />
    </svg>
  );
};
