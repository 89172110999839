import React, { useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import { assets } from '../../../utils';
import './password.scss'

const Password = ({ label, suffixIcon, styles, customClass, ...props }) => {

  const [field, meta] = useField(props);
  const [type, setType] = useState('password')

  const handleIconCick = () => {
    if(type==='password'){
        setType('text')
    } else {
        setType('password')
    }
  }
  return (
    <div className="mb-4">
      <div className='field-container' style={styles}>
        {label ? <label htmlFor={field.name}>{label}</label> : <></>}
        <input type={type}
          className={`form-control shadow-none ${customClass} ${meta.touched && meta.error && 'is-invalid'}`}
          {...field} {...props}
          autoComplete="off"
        />
        <img src={type==='password'?assets.visibilityOff:assets.visibilityOn} className={`password-icon ${meta.error ? 'd-none' : ''}`} onClick={handleIconCick} />
      </div>
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  )
}

export default Password;