import React, { useState } from 'react';
import { assets } from '../../utils';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import './sidebar.scss';
import Deletepopup from '../deletePopup/deletepopup';

export default function Sidebar() {
    const nav = useNavigate();
    const [showPopup, setShowPopup] = useState(false)
    const { logout } = useAuthContext();
    const navItems = [
        { id: 'users', name: 'Users', icon: assets.usersIcon, navigationTo: '/dashboard/users' },
    ];

    const handleLogout = () => {
        logout().then(resp => {
            nav('/login')
        })
    }

    return (
        <div className='sidebar-wrapper'>
            <div className='sidebar-nav'>
                <div style={{ display: 'flex' }}>
                    <span className='logo'><img className='fletcher-logo' src={assets.fletcherLogoBlack} /></span>
                    <img className='fletcher-text' src={assets.fletcherNewWhite} />
                </div>
                <nav className=''>
                    <ul className='sidebar-nav-items'>
                        {navItems.map(listItem =>
                            <li key={listItem.id}>
                                <NavLink className={(navData) => (navData.isActive ? 'active' : 'link')}
                                    to={listItem.navigationTo}>
                                    <span><img src={listItem.icon} /></span>
                                    <span className='name'>{listItem.name}</span>
                                </NavLink>
                            </li>
                        )}
                    </ul>
                </nav>
                <div className='logout-wrapper'>
                    <span onClick={()=> setShowPopup(true)} className='logout-btn'>
                        <span>Logout</span>
                        <img src={assets.logoutIcon} />
                    </span>
                </div>
            </div>
            {/* <div className='logout-icon'>
                <span className='logout-button' onClick={handleLogout}><span className='logout-icon-image'><img src={assets.logout} /></span><span>Logout</span></span>
            </div> */}
            <Deletepopup show={showPopup} label={'Logout Confirmation'} handleDelete={handleLogout}
                content='Are you sure you want to logout?' setShow={setShowPopup}
                primaryBtnText='Logout' />
        </div>
    )
}
