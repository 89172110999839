import React from 'react'
import Modal from 'react-modal';
import './addEditUser.scss'
import { assets } from '../../utils';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextField from '../forms/input/textfield';
import './addEditUser.scss'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');

export default function AddEditUser(props) {
    const { show, setShow, actionType, formSubmitHandler, selectedRow } = props
    const initialValues = {
        fullName: selectedRow.name || '',
        email: selectedRow.email || '',
        userType: ''
    }
    const validate = Yup.object({
        email: Yup.string().required("Email Required!"),
        fullName: Yup.string().required("Name Required!")
    });
    const handleFormSubmit = (vals) => {
        formSubmitHandler(actionType,vals)
     }
    return (
        <Modal
            isOpen={show}
            // onAfterOpen={afterOpenModal}
            onRequestClose={setShow}
            style={customStyles}
            contentLabel="adduser-popup"
        >
            <div className='adduser-popup-wrapper'>
                <div className='header'>
                    <span className='label'>{actionType === 'add' ? 'Add User' : 'Edit User'}</span>
                    <img onClick={() => setShow(false)} src={assets.crossIcon} />
                </div>
                <div className='content'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validate}
                            onSubmit={handleFormSubmit}
                        >
                            {(formik) => (
                                <Form style={{width:'100%'}}>
                                    <div style={{minWidth:'330px'}}>
                                    <div className='add-edit-user-field'>
                                        <label htmlFor='' className='add-edit-label'>Full Name</label>
                                        <TextField
                                            type='text'
                                            id='fullName'
                                            name='fullName'
                                            placeholder='Enter Full Name'
                                            customClass='primary-input'
                                        />
                                    </div>
                                    <div className='add-edit-user-field'>
                                    <label htmlFor='email' className='add-edit-label'>Email</label>
                                        <TextField
                                            type='text'
                                            id='email'
                                            name='email'
                                            disabled={actionType === 'edit'}
                                            placeholder='Enter Email'
                                            customClass='primary-input'
                                        />
                                    </div>
                                    <div className='add-edit-user-field user-type-field'>
                                    <label htmlFor='userType' className='add-edit-label'>User type</label>
                                        <select disabled name='userType' id='userType'>
                                            <option value='member'>Member</option>
                                        </select>
                                    </div>
                                    </div>
                                    <div className='actions'>
                                        <button type='submit' className='adduser-btn'>{actionType==='add'?'Create User':'Save'}</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                </div>
            </div>
        </Modal>
    )
}
