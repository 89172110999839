import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';

export default function ProtectedHOC({children}) {
    const { getLoggedInState } = useAuthContext()
    const location = useLocation();
    if(getLoggedInState()) return children;
    return <Navigate to="/login" state={{ from: location.pathname }} replace={true}/>;
}
