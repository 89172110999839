import React from 'react';
import { ErrorMessage, useField } from 'formik';
import './textfield.scss'

export default function TextField({label, type, customClass='', styles={}, ...props}) {
  const [field, meta] = useField(props);
  return (
    <div className="mb-4">
      <div className='field-container' style={styles}>
        {label ? <label htmlFor={field.name}>{label}</label> : <></>}
        <input type={type}
          className={`form-control shadow-none ${customClass} ${meta.touched && meta.error && 'is-invalid'}`}
          {...field} {...props}
          autoComplete="off"
        />
      </div>
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  )
}
