import { post, get, put, del, patch } from "./http-methods"

export const API = {
  signup: payload => post("/api/v1/signup/", payload),
  login: payload => post("/api/v1/login/admin/", payload),
  logout: () => get("/api/v1/logout/"),
  changePassword: (payload) => post('/api/v1/verify-forgot-password/',payload),
  changePasswordEmail: (payload) => post('/api/v1/forgot-password/',payload),
  getUsers: config => get('/api/v1/admin/user-list/',config),
  addUsers: payload => post('/api/v1/admin/user-list/',payload),
  editUser: (id, payload) => patch(`/api/v1/admin/user-list/${id}/`,payload),
  deleteUser: id => del(`/api/v1/admin/user-list/${id}/`),
}
