import React from 'react';
import { Navigate, useRoutes, Routes, Route } from 'react-router-dom';
import Login from './pages/login/login';
import Users from './pages/dashboard/users/users';
import Dashboard from './pages/dashboard/dashboard';
import Layout from './components/layout/layout';
import ProtectedHOC from './utils/ProtectedHOC';
import ChangePassword from './pages/change-password/changePassword';
import PasswordResetEmail from './pages/change-password/password-reset-email';

import AppScreen from './components/appScreen/appScreen';

import { ROUTE_NAMES } from './website/utils/navigation/routeNames';
import ScreenHome from './website/pages/home/home';
import ScreenTerms from './website/pages/terms/terms';
import ScreenPrivacy from './website/pages/privacy-policy/privacy-policy';
import ScreenContact from './website/pages/contact/contact';
import WebsiteScreen from './components/websiteScreen/websiteScreen';

export default function AppRoutes() {
  const routes = (
    <Routes>

      <Route
        element={
          <WebsiteScreen>
              <Dashboard />
          </WebsiteScreen>
        }
      >
        <Route path={ROUTE_NAMES.homePage} element={<ScreenHome />} index={true} />
        <Route path={ROUTE_NAMES.termsPage} element={<ScreenTerms />} />
        <Route path={ROUTE_NAMES.privacyPage} element={<ScreenPrivacy />} />
        <Route path={ROUTE_NAMES.contactPage} element={<ScreenContact />} />
      </Route>
      <Route
        path="/login"
        element={
          <AppScreen>
            <Login />
          </AppScreen>
        }
      />
      <Route
        path="/reset-password/verify/"
        element={
          <AppScreen>
            <ChangePassword />
          </AppScreen>
        }
      />
      <Route
        path="/reset-password"
        element={
          <AppScreen>
            <PasswordResetEmail />
          </AppScreen>
        }
      />
      {/* <Route element={<ProtectedHOC><Layout /></ProtectedHOC>}> */}
      <Route
        element={
          <ProtectedHOC>
            <AppScreen>
              <Layout />
            </AppScreen>
          </ProtectedHOC>
        }
      >
        <Route path="/dashboard" element={<Dashboard />}>
          <Route index element={<Navigate to="/dashboard/users" />} />
          <Route path="/dashboard/users" element={<Users />}></Route>
        </Route>
      </Route>
      {/* </Route> */}
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
  return routes;
}
