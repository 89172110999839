import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { Row } from 'react-bootstrap'
import { assets } from '../../utils'
import Password from '../../components/forms/password/password';
import Button from '../../components/forms/button/button';
import { Form, Formik } from 'formik';
import { useLocation, useSearchParams } from 'react-router-dom'
import { useAuthContext } from '../../contexts/AuthContext';
import { toast } from "react-toastify";
import '../login/login.scss'

export default function ChangePassword() {
    const { changePassword } = useAuthContext();
    const [authDetails, setAuthDetails] = useState({});
    const initialValues = { password: '', confirmPassword: '' }
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();

    const validate = Yup.object({
        password: Yup.string().required("Password Required!").matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
        'Password must be at least 8 characters long and contain at least one capital & small letter, one number and one special character'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Password must match')
            .required("Confrm Password Required!"),
    });

    useEffect(() => {
        const token = searchParams.get('token')
        const uid = searchParams.get('uid')
        setAuthDetails({ token, uid })
    }, [])

    const handlePasswordChangeSubmit = (vals) => {
        if (!authDetails.token && !authDetails.uid) {
            return
        }
        const payload = {
            token: authDetails.token,
            uid: authDetails.uid,
            new_password: vals.password,
            confirm_password: vals.confirmPassword
        }
        changePassword(payload).then(resp=>{
            if(resp?.data?.success){
                toast.success(resp.data.message,{autoClose:5000, className:'toast-success'})   
            } else {
                toast.error('Something went wrong',{autoClose:5000, className:'toast-fail'})
            }
        })
    }

    return (
        <div className='login-wrapper'>
            <div>
                <Row>
                    <img className='fletcher-logo' src={assets.fletcherLogo} />
                    <img className='fletcher-text' src={assets.fletcherNewLogo} />
                </Row>
                <Row>
                    <div className='login-heading signup-heading'>Reset Password</div>
                    <div className='signup-sub-heading'>Keep Your Account Secure with a New Password</div>
                </Row>
            </div>
            <div>
                <Row className='form'>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validate}
                        onSubmit={handlePasswordChangeSubmit}
                    >
                        {(formik) => (
                            <Form>
                                <div className='login-field reset-password'>
                                    <Password
                                        name='password'
                                        placeholder='Enter New Password'
                                        customClass='primary-input'
                                    />
                                </div>
                                <div className='login-field'>
                                    <Password
                                        name='confirmPassword'
                                        placeholder='Confirm New Password'
                                        customClass='primary-input'
                                    />
                                </div>
                                <div className='login-field login-button'>
                                    <Button variant='btn-dark'>Change Password</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Row>
            </div>
        </div>
    )
}
