import React, { useRef } from "react"
import { useTable, useExpanded, useSortBy } from "react-table"
import "./table.scss"
import { Col } from "react-bootstrap"
import { assets } from "../../utils"
import Loader from "../loader/loader"

function VTPTable({
    columns: userColumns,
    data,
    renderRowSubComponent,
    isLoading,
    rowsExpanded,
    height,
    initialSortKey,
    myClassName = '',
    id = '',
    isAsc,
    onRowClick = () => { }
}) {
    let scrollParentRef
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        customClass
    } = useTable(
        {
            columns: userColumns,
            initialState: {
                sortBy: [
                    {
                        id: initialSortKey || "",
                        desc: isAsc || false,
                    }
                ]
            },
            data: data || []
        },
        useSortBy
    )

    const Table = () => {
        return (
            <>
                <table id={id} className={myClassName} {...getTableProps()}>
                    <thead style={{ zIndex: '1' }}>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        onClick={() => column.toggleSortBy(!column.isSortedDesc)}
                                    >
                                        {column.render("Header")}
                                        {column.isSorted ? (column.isSortedDesc ? <img style={{ position: 'absolute' }} src={assets.defaultSort} /> : '') : ''}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.length > 0 && (
                            rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <React.Fragment key={i}>
                                        <tr
                                            className="tr-main"
                                            {...row.getRowProps()}
                                            onClick={() => onRowClick(row.original)}
                                        >
                                            {row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}
                                                        style={{ width: cell.column.width }}>
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                        {row.isExpanded ? (
                                            <tr>
                                                <td colSpan={visibleColumns.length}>
                                                    {renderRowSubComponent({ row })}
                                                </td>
                                            </tr>
                                        ) : null}
                                    </React.Fragment>
                                )
                            })
                        )}
                    </tbody>
                </table>
                {(!isLoading && !rows.length) &&
                    <Col className="text-center">
                        <p
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                color:'#fff'
                            }}
                        >
                            No results found
                        </p>
                    </Col>
                }
                {(isLoading) &&
                    <Col className="text-center">
                        <Loader style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%"
                            }} />
                    </Col>
                }
            </>
        )
    }

    return (
        <div
            style={{ height }}
            className={`${customClass} table-wrapper`}
            ref={node => {
                scrollParentRef = node
            }}
        >
            <Table />

        </div>
    )
}

export default React.memo(VTPTable)
